export default [
  {
    url: "https://d1drj6u6cu0e3j.cloudfront.net/1920x1080/smart/Place/40/290149/e3d58798756c75def0406020e3f5b11c.jpg",
    header: {
      heading: "Iglesia San Agustín",
      subheading: "Church",
      profileImage: "https://lh3.googleusercontent.com/-nwxVbDZgyPY/AAAAAAAAAAI/AAAAAAAAAAA/aISbrPCai3Y/w60-h60-p-rp-mo-ba4-br100/photo.jpg"
    }
  },
  {
    url: "https://d1drj6u6cu0e3j.cloudfront.net/1920x1080/smart/Place/108/553887/e4e4ab5cff82429334907dc4111ff289.jpg",
    header: {
      heading: "Louvre Museum",
      subheading: "Museum",
      profileImage: "https://lh3.googleusercontent.com/-nwxVbDZgyPY/AAAAAAAAAAI/AAAAAAAAAAA/aISbrPCai3Y/w60-h60-p-rp-mo-ba4-br100/photo.jpg"
    }
  },
  {
    url: "https://d1drj6u6cu0e3j.cloudfront.net/1920x1080/smart/Place/76/319571/256e4d5afedeb95fff35ddfce40593b1.jpg",
    header: {
      heading: "Louvre Museum",
      subheading: "Museum",
      profileImage: "https://lh3.googleusercontent.com/-nwxVbDZgyPY/AAAAAAAAAAI/AAAAAAAAAAA/aISbrPCai3Y/w60-h60-p-rp-mo-ba4-br100/photo.jpg"
    }
  },
  {
    url: "https://d1drj6u6cu0e3j.cloudfront.net/1920x1080/smart/Place/108/548926/1b7d8187ddc65976b2af37c25eea8c58.jpg",
    header: {
      heading: "Tuileries Garden",
      subheading: "Garden",
      profileImage: "https://lh3.googleusercontent.com/-nwxVbDZgyPY/AAAAAAAAAAI/AAAAAAAAAAA/aISbrPCai3Y/w60-h60-p-rp-mo-ba4-br100/photo.jpg"
    }
  },
  {
    url: "https://d1drj6u6cu0e3j.cloudfront.net/1080x720/smart/Place/76/320705/a564d5ac682b4330616b6390120ad511.jpg",
    header: {
      heading: "Tuileries Garden",
      subheading: "Garden",
      profileImage: "https://lh3.googleusercontent.com/-nwxVbDZgyPY/AAAAAAAAAAI/AAAAAAAAAAA/aISbrPCai3Y/w60-h60-p-rp-mo-ba4-br100/photo.jpg"
    }
  },
  {
    url: "https://d1drj6u6cu0e3j.cloudfront.net/1920x1080/smart/Place/108/550142/0ebacfd76a9aca41faef2edc0e2abd5b.jpg",
    header: {
      heading: "Tuileries Garden",
      subheading: "Garden",
      profileImage: "https://picsum.photos/1080/1920"
    }
  }
];
