import React from "react";

import { Stories } from "@tripian/react/lib";
import stories from "./data/StoriesTest";

const StoriesTest = () => {
  return <Stories stories={stories} defaultInterval={3500} loop={true} />;
};

export default StoriesTest;
