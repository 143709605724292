import React from "react";
import "./App.css";
import "@tripian/react/lib/min.css";

/* import ModalTest from "./sandbox/ModalTest";
import CardTest from "./sandbox/CardTest";
import RatingStarsTest from "./sandbox/RatingStarsTest";
import PriceTest from "./sandbox/PriceTest";
import ButtonTest from "./sandbox/ButtonTest";
import ImgDivTest from "./sandbox/ImgDivTest";
import ImageCoverTest from "./sandbox/ImageCoverTest"; */
import StoriesTest from "./sandbox/StoriesTest";

// import Card from "./sandbox/Card";

// import {ReactComponent as {Add as svgs.add}} from '@tripian/react/lib'
// import { ReactComponent as Add} from "./assets/svg/add.svg";

// require("react-dom");
// window.React2 = require("react");
// console.log(window.React1 === window.React2);

function App() {
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      {/* <div style={{ width: "100%", height: "70vh" }}> */}
      <StoriesTest />
      {/* <ModalTest />
        <br />
        <CardTest />
        <br />
        <ImageCoverTest />
        <br />
        <ImgDivTest />
        <br />
        <ButtonTest />
        <br />
        <RatingStarsTest />
        <br />
        <PriceTest /> */}
      {/* </div>
      <div style={{ width: "100%", height: "30vh", backgroundColor: "#dee2e6" }}>POI INFOS</div> */}
    </div>
  );
}

export default App;
